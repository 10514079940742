<template>
  <v-dialog
    :value="dialog"
    max-width="560"
    persistent
  >
    <v-card class="pa-4">
      <v-card-title class="text-h5">
        Deseja realmente redefinir a senha do usuário vinculado ao e-mail {{payload.email}}?
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        
        <v-btn
          @click="closeDialog()"
          color="error"
          text
        >
          Cancelar
        </v-btn>

        <primary-button @callAction="save()" label="Sim, Redefinir" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import PrimaryButton from "@/components/buttons/PrimaryButton";

export default {
  name: "PasswordResetDialog",
  components: { PrimaryButton },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    payload: {
      type: Object,
      default: () => {}
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    save() {
      this.resetPassword()  
    },

    resetPassword(){      
      let loader = this.$loading.show();     
      LoginService.resetPassword(this.payload)
        .then(() => {
          this.$toasted.show("Senha redefinida com sucesso.", {
            type: "success",
          });
          this.closeDialog();
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        }).finally(()=>{          
          loader.hide()
        });
    },
  },
};
</script>

<style scoped>
  ::v-deep html {
    overflow-y: hidden !important;
  }
</style>