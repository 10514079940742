import http from "@/services/http.service";

const apiUrl = "/states";

class StatesService {
  static list(params) {
    return http.get(`${apiUrl}`, { params });
  }
}

export default StatesService;
