<template>
  <div>
    <toolbar :toolbarName="'Meu Perfil'" :openDetails="true" />
    <row-details :show="true">
      <v-row align="center">
          <v-col class="d-flex justify-start">
            <custom-label-18 label="Informações" />
          </v-col>
        <v-spacer></v-spacer> 
      </v-row>
      <v-row>
        <col-details column="8" md="0" name="Nome" :item="payload.user.name" />
        <col-details column="8" md="0" name="CPF" :item="payload.cpf" sensitive mask="cpf"/>
        <col-details column="8" md="0" name="E-mail" :item="payload.user.email" />
      </v-row>

      <v-row>
        <col-details
          column="4" 
          name="Status"
          :item="this.status"
        />

        <v-col cols="8">
          <p>
            <span><b>AS Responsável</b></span> <br />  
              <span class="font-weight-light">
                {{ payload.responsible_success_advisor.name }}
              </span>
          </p>
        </v-col>
        <col-details column="8" name="Qtd. de Fazendas" :item="farms.length" />
      </v-row>

      <v-row>
        <v-col class="d-flex justify-start">
          <custom-label-18 label="Fazendas Vinculadas" />
        </v-col>
      </v-row>

      <data-table
        :footer-props="{
          'items-per-page-text': 'Linhas por página',
          'items-per-page-all-text': 'Todos',
        }"
        :headers="headers"
        :data="farms"
        :objectName="'fazendas'"
        no-data-text="Nenhum registro de manejo encontrado"
        sort-by="id"
        no-results-text="Nenhuma fazenda encontrada"
        :noOutline="true"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ "#" + item.id }}
        </template>

        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.city`]="{ item }">
          {{ item.city }}
        </template>

        <template v-slot:[`item.state`]="{ item }">
          {{ item.state.uf }}
        </template>

        <template v-slot:[`item.size`]="{ item }">
          {{ (item.farm_size ? item.farm_size : "0") + " ha" }}
        </template>
      </data-table>

      <template slot="other-row-details">
        <v-row>
          <v-col cols="12 md-6" class="d-flex justify-start">
            <custom-label-18 label="Segurança da Conta" />
          </v-col>
          <v-col class="d-flex justify-end">
            <primary-button 
            @callAction="confirmResetPassword"
            :label="'Redefinir Senha'" 
            />
          </v-col>
        </v-row>
      </template>
    </row-details>

    <confirm-reset-password-dialog
      :payload="payload"
      :dialog.sync="showConfirmResetPasswordDialog"
      @closeDialog="closeConfirmResetPasswordDialog"
    />
  </div>
</template>

<script>
//Components
import RowDetails from "@/components/crud/Details.vue";
import ColDetails from "@/components/crud/DetailsItem";
import Toolbar from "@/components/crud/Toolbar";
import CustomLabel18 from "@/components/texts/CustomLabel18.vue";
import DataTable from "@/components/data-table/DataTable.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

//Services - Domains
import Producers from "@/domain/producers/producers";
import Farms from "@/domain/farms/farms";
import States from "@/domain/states/states";
import { getItem } from "@/services/local-storage.service";
import ConfirmResetPasswordDialog from '@/components/dialogs/login/ConfirmResetPasswordDialog.vue';

export default {
  name: "Producer-profile",

  components: {
    RowDetails,
    ColDetails,
    Toolbar,
    CustomLabel18,
    DataTable,
    PrimaryButton,
    ConfirmResetPasswordDialog,
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        {
          text: "Nome da fazenda",
          value: "name",
        },
        {
          text: "Cidade",
          value: "city",
        },
        {
          text: "Estado",
          value: "state",
        },
        {
          text: "Tamanho",
          value: "size",
        },
        // { text: "Detalhes", value: "actions", sortable: false },
      ];
    },
    status(){
      return this.user_status ? "Ativo" : "Inativo";
    }
  },

  data() {
    return {
      payload: {},
      farms: [],

      producerService: null,
      farmsService: null,
      statesService: null,

      showConfirmResetPasswordDialog:false,

      user_status:false,
    };
  },

  methods: {
    initilize() {
      this.producerService = Producers;
      this.farmsService = Farms;
      this.statesService = States;

      this.getProducer();
    },
    getProducer() {
      let loader = this.$loading.show();
      const user =  JSON.parse(getItem("user"));

      this.producerService.getProducerByUserId(user.id).then((res) => {
        this.payload = res.data;
        this.farms=this.payload.farms;
        this.user_status=user.is_active;

        loader.hide()
      }).catch(loader.hide());
    },
    closeConfirmResetPasswordDialog(){
      this.showConfirmResetPasswordDialog=false;
    },
    confirmResetPassword(){
      this.payload.email=this.payload.user.email
      this.showConfirmResetPasswordDialog=true;
    }
  },
  beforeMount() {
    this.initilize();
  },
};
</script>
